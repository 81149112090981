import React from "react"
import ActuateMinds from "../components/actuate-minds/actuate-minds"
import Contact from "../components/contact/contact"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"

export default function ContactUs() {
  return (
    <Layout active={"contact"}>
      <Seo
        title="Talk to us"
        description="We are always ready to help for any kind of support. Fill out the contact us form, we will contact you shortly or mail us at info@actuateminds.com"
      />
      <Contact />
      <ActuateMinds />
    </Layout>
  )
}
